/*
 This code defines and exports an object containing various route paths.
    Example usage:
    -----------------------------------------------------------------------------
        import RoutePaths from './RoutePaths';

 */

import { generatePath } from 'react-router';

const DASHBOARD_SUBPAGE = '/mine/:subpage';
const LIST_DETAIL = '/list/:setId';
const SUB_IMPORT = 'import';
const SUB_LIB_IMPORT = 'lib_import';

const LIB_IMPORT = '/lib_import';
const FLASHCARD_PAGER = '/flashcard/:setId';
const LEARN_PAGER = '/learn/:setId';
const LISTENING_PAGER = '/listening/:setId';
const EXAM_PAGER = '/exam/:setId';
const LIST_EDIT = '/listedit/:setId';
const TRASH = '/trash';

const LAN_EN_HOME = '/en';

const RoutePaths = {
  HOME: '/',
  DASHBOARD: '/mine',
  PROFILE: '/profile', // this is a transition url.
  DASHBOARD_SUBPAGE,
  HELP: 'https://blog.scitudy.com',
  SUB_IMPORT,
  LIB_IMPORT,
  SUB_LIB_IMPORT,
  FLASHCARD_PAGER,
  LEARN_PAGER,
  LISTENING_PAGER,
  EXAM_PAGER,
  SUB_HOMEWORK: 'homework',
  SUB_TRASH: 'trash',
  SUB_AI_TEACHER: 'ai-teacher',
  LIST_DETAIL,
  LIST_EDIT,
  LAN_EN_HOME,
  TRASH,
  mergeMine: (subpage) => {
    return generatePath(DASHBOARD_SUBPAGE, { subpage });
  },
  // to create a url to view a list.
  mergeList: (id) => {
    return generatePath(LIST_DETAIL, { setId: id });
  },
  // to create a url for  editing  a list.
  editList: (id) => {
    return generatePath(LIST_EDIT, { setId: id });
  },
  mergeImport: (id, type) => {
    let url = RoutePaths.mergeMine(SUB_IMPORT) + '?setId=' + id;

    if (!type) {
      throw new Error('type is null.');
    }
    if (type) {
      url += '&type=' + type;
    }
    return url;
  },
  getParams: (url) => {
    if (!url.includes('?')) return {};

    const queryString = url.split('?')[1];
    const params = {};

    if (queryString.includes('&')) {
      queryString.split('&').reduce((acc, param) => {
        const [key, value] = param.split('=');
        acc[key] = value;
        return acc;
      }, params);
    } else {
      const [key, value] = queryString.split('=');
      params[key] = value;
    }
    return params;
  },
};

export default RoutePaths;
